import BaseApi from "./BaseApi";
const USER_TOKEN = "USER_TOKEN";
const USER_DATA = "USER_DATA";

export default {
  Login: (body: any) => {
    return BaseApi.post("/auth/login", body);
  },
  AttemptLogin: (body: any) => {
    return BaseApi.post("/attempt-login", body);
  },
  LoginWithCode: (body: any) => {
    return BaseApi.post("/login-with-code", body);
  },
  setToken: (body: any) => {
    localStorage.setItem(USER_TOKEN, JSON.stringify(body));
  },
  getToken: () => {
    return JSON.parse(localStorage.getItem(USER_TOKEN) || "{}");
  },
  isLoggedIn: () => {
    let token = localStorage.getItem(USER_TOKEN);

    return token ? true : false;
  },
  logout: () => {
    localStorage.clear();

    setTimeout(() =>{
      window.location.href = "/";
      // window.location.reload();
    },300);
  },
  setUserData: (body: any) => {
    localStorage.setItem(USER_DATA, JSON.stringify(body));
  },
  getUserData: () => JSON.parse(localStorage.getItem(USER_DATA) || "{}"),
  Me: () => {
    return BaseApi.get("/auth/me");
  },
};
