import {
  Button,
  Drawer,
  Dropdown,
  Menu,
  Popconfirm,
  Space,
  Table,
  Typography
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Offer } from "../../classes/Offer";
import { OfferUrl } from "../../classes/OfferUrl";
import APP_ROUTE from "../../routes/routes";

interface Options {
  open: boolean;
  offer?: Offer;
  onUpdate: () => void;
  onClose: () => void;
}

const UrlsForm: FC<Options> = ({ open, offer, onUpdate, onClose }: Options) => {
  const navigate = useNavigate();

  const OptionsMenu = (record: OfferUrl) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const onRemove = () => {};
    const Itens = () => {
      return (
        <Menu>
          <Menu.Item onClick={() => navigate(APP_ROUTE.OFFER_FORM(record.id))}>
            Editar
          </Menu.Item>
          <Menu.Item
            danger
            onClick={(e) => {
              return false;
            }}
          >
            <Popconfirm
              title="Title"
              description={`Remover o ${record.url}?`}
              okButtonProps={{ loading: confirmLoading }}
              onConfirm={onRemove}
              onOpenChange={() => console.log("open change")}
            >
              Remover
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  const columns = [
    {
      title: "Comparação",
      dataIndex: "compare",
      render: (text: any, record: OfferUrl) => (
        <Typography.Text>
          {record.compare == "CONTAINS" && "Contém"}
          {record.compare == "DOMAIN" && "Dominio"}
          {record.compare == "START_WITH" && "Inicia em"}
          {record.compare == "EQUALS" && "Igual a"}
        </Typography.Text>
      )
    },
    {
      title: "Url",
      dataIndex: "url"
    }
  ];
  return (
    <>
      <Drawer
        closable={true}
        onClose={onClose}
        title={`Urls monitoradas na oferta '${offer?.name}'`}
        open={open}
        destroyOnClose={true}
        size={"large"}
      >
        <Table size="large" dataSource={offer?.OfferUrls} columns={columns} />
      </Drawer>
    </>
  );
};

export default UrlsForm;
