import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

import APP_ROUTE from "../../routes/routes";

const PageError = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => navigate(APP_ROUTE.DASHBOARD())}>
          Back Home
        </Button>
      }
    />
  );
};

export default PageError;
