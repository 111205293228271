import BaseApi from "./BaseApi";

export default {
  list: () => {
    return BaseApi.get(`/category/list`);
  },
  addNew: (body: any) => {
    return BaseApi.post("/category", body);
  }
};
