import { OfferUrl } from "../classes/OfferUrl";
import BaseApi from "./BaseApi";

export default {
  list: (filter?: any) => {
    return BaseApi.get(`/offer`, { params: filter });
  },
  addNew: (body: any) => {
    return BaseApi.post(`/offer`, body);
  },
  checkUrls:(body:any, id?:number) =>{


    const url = !!id ? `/offer-url/check/${id}` : `/offer-url/check`; 
    return BaseApi.post(url,body);
  },
  updateItem: (body: any, id: number) => {
    return BaseApi.put(`/offer/${id}`, body);
  },
  getOne: (id: number) => {
    return BaseApi.get(`/offer/${id}`);
  },
  removeItem: (id: number) => {
    return BaseApi.delete(`/offer/${id}`);
  },
  restoreItem: (id: number) => {
    return BaseApi.put(`/offer/${id}/restore`);
  }
};
