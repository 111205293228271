const APP_ROUTE: any = {
  HOME: () => "/",
  LOGIN: () => "/login",
  DASHBOARD: () => "/dashboard",
  ONBOARDING: () => "/onboarding",
  ONBOARDING_NEW: () => "/onboarding/new",
  ONBOARDING_FORM: (id: number) => `/onboarding/form/${id ? id : ":id"}`,
  OFFER: () => `/offer`,
  OFFER_NEW: () => `/offer/new`,
  OFFER_FORM: (id: number) => `/offer/form/${id ? id : ":id"}`
};

export default APP_ROUTE;
