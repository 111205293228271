import { useEffect, useRef, useState } from "react";
import Upload, { RcFile } from "antd/es/upload";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  UploadFile,
  UploadProps,
  DatePicker,
  Image as ImageView,
  Space,
  Button,
  Radio,
  Checkbox,
  Divider,
  theme,
  Row,
  Col,
  Typography,
  Select,
  InputRef,
  message,
  notification,
  Modal
} from "antd";
import {
  PlusOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";


import { Offer } from "../../classes/Offer";
import { CategoryDict } from "../../classes/Category";
import OfferApi from "../../services/OfferApi";
import CategoryApi from "../../services/CategoryApi";
import APP_ROUTE from "../../routes/routes";
import FormUrls from "./formUrl";
import { OfferUrl } from "../../classes/OfferUrl";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;



const OfferFormPage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [formMult] = Form.useForm();
  const [isSending, setIsSending] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loadingNewCategory, setLoadingNewCategory] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [currentData, setCurrentData] = useState<Offer>();
  const [categories, setCategories] = useState<CategoryDict[]>([]);
  const inputRef = useRef<InputRef>(null);
  const [name, setName] = useState("");
  const [listUrl, setListUrl] = useState<OfferUrl[]>([]);

  const [formMultipleUrl, setFormMultipleUrl] = useState(false);

  const [sendNewImage, setSendNewImage] = useState(false);
  const [image, setImage] = useState<any>();

  const [hasError, setHasError] = useState<any>();

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  useEffect(() => {
    CategoryApi.list().then((resp) => {
      setCategories(resp?.data);
    });
  }, []);

  useEffect(() => {
    if (!!id) {
      OfferApi.getOne(Number(id)).then((resp) => {
        setCurrentData(resp?.data);
        let formfields = resp?.data;
        let format = 'YYYY-MM-DD HH:mm';
        formfields = {
          ...formfields,
          ...{
            // image:image,
            urls: formfields.OfferUrls,
            btn_bg:!!formfields.btn_bg ? formfields.btn_bg : '#465EFF',
            btn_text_color:!!formfields.btn_text_color ? formfields.btn_text_color : '#FCFC30',
            interval:[
              !!formfields.startOn ? dayjs(formfields.startOn,format) : '',
              !!formfields.endOn ? dayjs(formfields.endOn,format) : ''
            ]
          }
        };

        if(formfields.OfferUrls){
          setListUrl(formfields.OfferUrls);
        }
        console.log(formfields);
        form.setFieldsValue(formfields);
      });
      setIsEdit(true);
    }
  }, [id]);

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    newFileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target?.result);
      };
      if(!!file && !!file.originFileObj)
        reader.readAsDataURL(file.originFileObj);
    });
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };


  const onSubmit = async() =>{
    let values = form.getFieldsValue();

    values = {...values,...{
      urls:listUrl,
    }}


    if(!!image){
      values = {...values, ...{image:image, hasImage:true}}
    }else{
      values = {...values, ...{image:image, hasImage:false}}
    }

    try{
      var request = (!!id && Number(id) > 0) ? await OfferApi.updateItem(values, Number(id)) : await OfferApi.addNew(values);

      message.success(request.data.msg);

      navigate(APP_ROUTE.OFFER());
    }catch(ex){
      message.error('Não foi possivel salvar a oferta');
    }
        
  }

  function feedBack(){
    if(typeof hasError === "undefined"){
      onSubmit();
    }else if(hasError == true){
      console.log('that')
      
    }
  }


  const handleSubmit = async(values: any) => {

    const urls = [...listUrl];
    let validate = await OfferApi.checkUrls({urls:urls}, !!id ? Number(id) : 0);

    validate.data?.forEach((item:any, index:number) =>{
      urls[index].compare = item.value.body.compare;
      urls[index].offer_id = item.value.body.offer_id;
      urls[index].url = item.value.body.url;

      if(item.value.allowInsert == false){
        urls[index].errors = `Url já cadastrada na oferta ${item.value.offer.name}`;
        setHasError(true);
      }else{
        urls[index].errors = null;
      }
      setListUrl(urls);
    });
    
    let check = urls.filter((item) =>{
      return item.errors != null;
    })

    if(check.length === 0){
      onSubmit();
    }else{
      Modal.warning({
        title:'Ops',
        content:(
          <div>
            <p>Algumas urls já estão cadastradas em outras ofertas!</p>
          </div>
        ),
        okText:'Confirmar cadastro',
        cancelText:'Revistar oferta',
        closable:true,
        onOk:() =>{
          setHasError(false);
          onSubmit()
        },
        onCancel:() =>{
          setHasError(false);
          Modal.destroyAll()
        }
      })
    }
    

  };


  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    console.log(name);
    setLoadingNewCategory(true);
    CategoryApi.addNew({ name: name })
      .then((resp) => {
        setCategories([...categories, resp?.data.category]);
        setName("");
      })
      .finally(() => {
        setLoadingNewCategory(false);
      });

    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };



  

  return (
    <>
      <div
        style={{ padding: 24, minHeight: 380, background: colorBgContainer }}
      >
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <Title level={3}>{isEdit ? "Novo" : "Editar"} Oferta</Title>
          </Col>
          <Col>
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                navigate(-1);
              }}
            >
              Voltar
            </Button>
          </Col>
        </Row>

        <Divider />

        <Form form={form} layout={"vertical"} onFinish={handleSubmit}>
          <Form.Item label="Imagem" name="image">
            {form.getFieldValue("image") && (
              <>
                <ImageView
                  src={`https://stgbbchromecashback.blob.core.windows.net/images/${form.getFieldValue(
                    "image"
                  )}`}
                />
                <br />
                <br />
              </>
            )}

            <Upload
              action={""}
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => {
                return false;
              }}
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
            >
              {fileList.length < 1 && "+ Upload"}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Titulo"
            name="name"
            rules={[
              {
                required: true,
                message: "Campo obrigatório"
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Row gutter={[16,16]}>
            <Col>
               <Form.Item name="enable" valuePropName="checked">
                <Checkbox>Ativo</Checkbox>
              </Form.Item>
            </Col>
            <Col>
               <Form.Item name="show_only_once" valuePropName="checked">
                <Checkbox>Mostrar apenas uma vez?</Checkbox>
              </Form.Item>
            </Col>
          </Row>
         
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                label="Link"
                name="url"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório"
                  }
                ]}
                help={'Url de redirecionamento da oferta'}
              >
                <Input type="url" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Tipo"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório"
                  }
                ]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="FLOATING">Flutuante</Radio.Button>
                  <Radio.Button value="POPUP">Pop-up</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
            
          <Row justify={"space-between"} gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16,16]}>
                <Col span={8}>
                <Form.Item
                  label="Categoria"
                  name="category_id"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório"
                    }
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Categorias"
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Categoria"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                          />
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            loading={loadingNewCategory}
                            onClick={addItem}
                          >
                            Nova
                          </Button>
                        </Space>
                      </>
                    )}
                    options={categories.map((item) => ({
                      label: item.name,
                      value: item.id
                    }))}
                  />
                </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Período de veiculação" name="interval">
                <RangePicker
                  placeholder={["Data de inicio", "Data fim"]}
                  showTime={{
                    hideDisabledOptions: true
                  }}
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>
            </Col>
            </Row>
          </Col>
          </Row>
          
          <Row justify={"space-between"} gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16,16]}>
                <Col span={8}><Form.Item
                label="Texto do botão"
                name="btn_text"
                help={
                  "Se não for informado um texto para o botão, não será exibido um botão para o usuário, então o clique será na imagem da oferta."
                }
              >
                <Input />
              </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                    label="Cor de fundo do botão"
                    name="btn_bg"
                  >
                  <Input type="color" defaultValue={'#465EFF'}/>
                </Form.Item>
              </Col>
                <Col span={8}>
                <Form.Item
                  label="Cor de Texto do botão"
                  name="btn_text_color"
                >
                  <Input type="color" defaultValue={'#FCFC30'}/>
                </Form.Item>
              </Col>
              </Row>
            </Col>
          </Row>

          <Divider />
          

          <FormUrls listUrls={listUrl} offerId={Number(id)} updateList={(e) => setListUrl(e)}/>

          <Divider />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isEdit ? "Atualizar" : "Cadastrar"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default OfferFormPage;
