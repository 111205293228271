import { Divider, Image, Menu, Space, Typography } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useNavigate } from "react-router-dom";
import {
  HighlightOutlined,
  TagOutlined,
  DashboardOutlined
} from "@ant-design/icons";

import { useAppContext } from "../../context/app-context";

import APP_ROUTE from "../../routes/routes";

const AppMenu = () => {
  const navigate = useNavigate();
  const { collapsedMenu } = useAppContext();

  return (
    <Sider theme="light" trigger={null} collapsible collapsed={collapsedMenu}>
      <Space direction={"horizontal"}>
        <Image
          preview={false}
          width={60}
          src={require("../../assets/images/brand.png")}
        />
      </Space>
      <Divider style={{ margin: "5px 0 !important" }} />

      <Menu
        mode="inline"
        items={[
          // {
          //   key: "1",
          //   icon: <DashboardOutlined />,
          //   label: "Inicio",
          //   onClick: () => navigate(APP_ROUTE.DASHBOARD())
          // },
          {
            key: "1",
            icon: <TagOutlined />,
            label: "Ofertas",
            onClick: () => navigate(APP_ROUTE.OFFER())
          },
          {
            key: "2",
            icon: <HighlightOutlined />,
            label: "Onboarding",
            onClick: () => navigate(APP_ROUTE.ONBOARDING())
          }
        ]}
      ></Menu>
    </Sider>
  );
};

export default AppMenu;
