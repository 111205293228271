import { FC, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  message,
  Upload,
  UploadFile,
  UploadProps,
  Image as ImageView,
  Row,
  Col,
  Typography,
  theme,
  Divider,
  Checkbox,
  Space
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/es/upload";

import { OnBoarding } from "../../classes/Onboarding";
import OnboardingApi from "../../services/OnboardingApi";
import { DrawerOptions } from "../../classes/DrawerOptions";
import { useNavigate, useParams } from "react-router-dom";
import APP_ROUTE from "../../routes/routes";

const { Title, Text } = Typography;

const FormOnboarding = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [changeImage, setChangeImage] = useState(true);

  const [currentData, setCurrentData] = useState<OnBoarding>();

  const [image, setImage] = useState<any>();

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  useEffect(() => {
    if (!!id) {
      OnboardingApi.Get(Number(id)).then((resp) => {
        setCurrentData(resp?.data);
        form.setFieldsValue(resp?.data);
      });
      setChangeImage(false);
      setIsEdit(true);
    }
  }, [id]);

  useEffect(() => {
    if (!!currentData) {
      form.setFieldsValue(currentData);
    }
  }, [currentData]);

  function closeDrawer() {
    form.resetFields();
    setFileList([]);
  }

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    newFileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target?.result)
        // file.base64 = e.target.result;
        setImage(e.target?.result);
      };
      if(!!file && !!file.originFileObj)
        reader.readAsDataURL(file.originFileObj);
    });
    // reader.onload = (e: any) => {
    //   file.base64 = e.target.value;
    //   console.log(e.target.value);
    //   setImage(e.target.result);
    // };

    // reader.readAsDataURL(file.originFileObj);

    
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleSubmit = async(values: any) => {
    if(!!image){
      values = {...values, ...{image:image, hasImage:true}}
    }else{
      values = {...values, ...{image:image, hasImage:false}}
    }

    if (isEdit) {
      OnboardingApi.updateItem(values, Number(id))
        .then((resp) => {
          navigate(APP_ROUTE.ONBOARDING());
          message.success(resp?.data.msg);
          closeDrawer();
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setIsSending(false);
        });
    } else {
      OnboardingApi.addItem(values)
      .then((resp) => {
        navigate(APP_ROUTE.ONBOARDING());
        message.success(resp?.data.msg);
        closeDrawer();
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setIsSending(false);
      });
    }
  };

  return (
    <>
      <div
        style={{ padding: 24, minHeight: 380, background: colorBgContainer }}
      >
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <Title level={3}>{isEdit ? "Atualizar" : "Novo"} onboarding</Title>
          </Col>
          <Col>
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                navigate(-1);
              }}
            >
              Voltar
            </Button>
          </Col>
        </Row>

        <Divider />
        <Form form={form} layout={"vertical"} onFinish={handleSubmit}>
          <Form.Item
            label="Titulo"
            name="name"
            rules={[
              {
                required: true,
                message: "Campo obrigatório"
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="enable" valuePropName="checked">
            <Checkbox>Ativo</Checkbox>
          </Form.Item>

          <Form.Item label="Imagem" name="image">
            {currentData?.image && (
              <Space direction="vertical" align="center">
                <ImageView
                  src={`https://stgbbchromecashback.blob.core.windows.net/images/${currentData.image}`}
                />

                <Button
                  danger
                  onClick={() => [setChangeImage(true), setFileList([])]}
                >
                  Trocar imagem
                </Button>
              </Space>
            )}
            <br />
            <br />
            {changeImage && (
              <Upload
                action={""}
                listType="picture-card"
                maxCount={1}
                beforeUpload={() => {
                  return false;
                }}
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
              >
                {fileList.length < 1 && "+ Upload"}
              </Upload>
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isEdit ? "Atualizar" : "Cadastrar"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default FormOnboarding;
