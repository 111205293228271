import { BrowserRouter } from "react-router-dom";
import ProvideLayout from "./context/app-context";
import CashBackBBRoute from "./routes";

const App = () => {
  return (
    <BrowserRouter>
      <ProvideLayout>
        <CashBackBBRoute />
      </ProvideLayout>
    </BrowserRouter>
  );
};

export default App