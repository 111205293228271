import axios from "axios";
import { useAppContext } from "../context/app-context";
import AuthApi from "./AuthApi";

const BaseApi = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  responseType: "json",
  maxContentLength: 2000 * 100,
  timeout: 1000 * 60,
});

BaseApi.interceptors.request.use(async (config) => {
  const { access_token } = AuthApi.getToken();

  if (access_token) {
    config.headers!.Authorization = `Bearer ${access_token}`;
  }

  return config;
});

BaseApi.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (error.response.status == 403 || error.response.status == 401) {
      AuthApi.logout();
      return;
    }
    return Promise.reject(error);
  }
);

export default BaseApi;