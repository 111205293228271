import { Button, Col, Row, theme, Typography } from "antd";
import { Header } from "antd/lib/layout/layout";
import { useNavigate } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined
} from "@ant-design/icons";

import { useAppContext } from "../../context/app-context";
import AuthApi from "../../services/AuthApi";
import APP_ROUTE from "../../routes/routes";

const AppHeader = () => {
  const { collapsedMenu, setCollapsedMenu, setUser, setIsLoggedUser } =
    useAppContext();
  const navigate = useNavigate();
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  function logout() {
    AuthApi.logout();
    navigate(APP_ROUTE.LOGIN());
    setIsLoggedUser(false);
    setUser(undefined);
  }

  return (
    <Header style={{ padding: 0, background: colorBgContainer }}>
      <div className="bar-header">
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <Row>
              <Button
                type="text"
                icon={
                  collapsedMenu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                }
                onClick={() => setCollapsedMenu(!collapsedMenu)}
              />
              <Typography.Title level={3} className="no-margin">
                Extensão BB
              </Typography.Title>
            </Row>
          </Col>
          <Col>
            <Button icon={<LogoutOutlined />} type={"primary"} onClick={logout}>
              Sair
            </Button>
          </Col>
        </Row>
      </div>
    </Header>
  );
};

export default AppHeader;
