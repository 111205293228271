import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Table,
  Typography,
  Image,
  Menu,
  Dropdown,
  Space,
  Divider,
  Row as RowLayout,
  message,
  Popconfirm
} from "antd";
import { PlusOutlined, DownOutlined, MenuOutlined } from "@ant-design/icons";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import { Paginated } from "../../classes/Paginated";
import { OnBoarding } from "../../classes/Onboarding";
import OnboardingApi from "../../services/OnboardingApi";
import FormOnboarding from "./form";
import { Navigate, useNavigate } from "react-router-dom";
import APP_ROUTE from "../../routes/routes";

const { Title, Text } = Typography;

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

const Onboarding = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState<Paginated<OnBoarding>>();
  const [itens, setItens] = useState<OnBoarding[]>([]);

  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setReorder] = useState(false);
  const [currentItem, setCurrentItem] = useState<OnBoarding>();

  const [filter, setFilter] = useState({});

  useEffect(() => {
    onload();
  }, []);

  useEffect(() => {
    onload();
  }, [filter]);

  useEffect(() => {
    if (order) {
      let newOrder: Array<{ id: number; order: number }> = [];
      itens.forEach((item, index: number) => {
        newOrder.push({
          id: Number(item.id),
          order: index
        });
      });

      OnboardingApi.reorder(newOrder)
        .then((resp) => {
          message.success(resp?.data.msg);
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setReorder(false);
        });
    }
  }, [itens]);

  useEffect(() => {
    if (!!data) {
      setItens(data?.data);
    }
  }, [data]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setReorder(true);
      setItens((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  function onload() {
    setLoading(true);
    setItens([]);
    OnboardingApi.listAll(filter)
      .then((resp) => {
        let response = resp?.data;
        let newItens = response.data.map((item: OnBoarding) => {
          return { ...item, ...{ key: item.id } };
        });
        response = { ...response, ...{ data: newItens } };
        setData(response);
        setItens(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function closeDrawer() {
    setIsEdit(false);
    setCurrentItem(undefined);
    setShowForm(false);
  }

  const columns = [
    {
      key: "sort"
    },
    {
      title: "Imagem",
      key: "name",
      render: (text: any, record: OnBoarding) => (
        <>
          <Image
            src={`https://stgbbchromecashback.blob.core.windows.net/images/${record.image}`}
            width={50}
          />
        </>
      )
    },
    {
      title: "Nome",
      dataIndex: "name"
    },

    {
      title: "Ativo",
      dataIndex: "enable",
      render: (text: any, record: OnBoarding) => (
        <>{record.enable ? "Sim" : "Não"}</>
      )
    },
    {
      title: "",
      dataIndex: "options",
      render: (text: any, record: OnBoarding) => <OptionsMenu {...record} />
    }
  ];

  const OptionsMenu = (record: OnBoarding) => {
    const [confirmLoading, setConfirmLoading] = useState(false);

    const onRemove = () => {
      setConfirmLoading(true);
      OnboardingApi.removeItem(Number(record.id))
        .then((resp) => {
          message.success(resp?.data.msg);
          onload();
        })
        .catch((e) => {
          message.error(e.response.data.msg);
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    };

    const Itens = () => {
      return (
        <Menu>
          <Menu.Item
            onClick={() => navigate(APP_ROUTE.ONBOARDING_FORM(record.id))}
          >
            Editar
          </Menu.Item>
          <Menu.Item danger>
            <Popconfirm
              title="Title"
              description="Open Popconfirm with Promise"
              okButtonProps={{ loading: confirmLoading }}
              onConfirm={onRemove}
              onOpenChange={() => console.log("open change")}
            >
              Remover
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <RowLayout justify={"space-between"} align={"middle"}>
        <Col>
          <Title level={3}>Onboarding</Title>
          <Text>
            Os itens do onboarding, serão mostrados de acordo com a ordem da
            tabela abaixo
          </Text>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(APP_ROUTE.ONBOARDING_NEW())}
          >
            Item
          </Button>
        </Col>
      </RowLayout>

      <Divider />
      {itens.length > 0 && (
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            items={itens.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row
                }
              }}
              rowKey="key"
              columns={columns}
              dataSource={itens}
            />
          </SortableContext>
        </DndContext>
      )}
    </>
  );
};

export default Onboarding;
