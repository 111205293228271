import { Button, Col, Form, Input, Modal, Radio, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import {
    PlusOutlined,
    MinusCircleOutlined
  } from "@ant-design/icons";
import { OfferUrl } from "../../classes/OfferUrl";
import TextArea from "antd/es/input/TextArea";
import OfferApi from "../../services/OfferApi";

const {Title, Text} = Typography;

interface Options{
    listUrls:Array<OfferUrl>;
    updateList:(val:Array<OfferUrl>) => void;
    offerId:number;
}

const FormUrls:FC<Options> = ({listUrls, offerId,updateList}:Options) =>{
    const [currentList, setCurrentList] = useState<OfferUrl[]>([]);
    const [formMultipleUrl,setFormMultipleUrl] = useState(false);
    const [currentCompare, setCurrentCompare] = useState<"EQUALS" | "CONTAINS" | "START_WITH" | "DOMAIN">();

    const [form] = Form.useForm();

    useEffect(() =>{
        if(currentList.length == 0){
            setCurrentList(listUrls);
        }
    },[listUrls]);

    useEffect(() =>{
        if(currentList.length >0){
            updateList(currentList);
        }
    },[currentList])

    const AddRow = () =>{
        const urls = [...currentList];

        const newItem = {
            url: '',
            compare: 'EQUALS',
            offer_id: !!offerId ? offerId : 0
        } as OfferUrl;
        
        setCurrentList([...urls, newItem]);
    }

    const OnUpdateRow = (key:any, value:any, index:number) =>{
        const urls = [...currentList];

        if(key == 'compare'){
            urls[index].compare = value;
        }

        if(key == 'url'){
            urls[index].url = value;
        }        

        // OfferApi.checkUrls({urls:urls}).then((resp) =>{
        //     console.log(resp);
        // })
        
    }

    const RemoveRow = (index:number) =>{
        
        const tempUrls = [...currentList];
        if(index > -1){
            tempUrls.splice(index,1);
        }
        setCurrentList(tempUrls);
    }

    const AddMultUrl = (values:any) =>{
        let urls = values.urls.split(', ');
        let list = [...currentList];

        urls.forEach((item:string, index:number) =>{
            list.push({
                url: item.trim(),
                compare: !!currentCompare ? currentCompare : 'EQUALS',
                offer_id: !!offerId ? offerId : 0,
                key: index.toString()
            });
        })

        setCurrentList(list);
        setFormMultipleUrl(false);
    
        form.resetFields();
      }

    return(
        <>
            <Modal title={'Urls em lote'} 
                open={formMultipleUrl} 
                destroyOnClose={true} 
                closable={true} 
                onOk={() => form.submit()} 
                onCancel={() => [
                    setFormMultipleUrl(false), 
                    form.resetFields()
                ]}
            >
                <Form form={form} onFinish={AddMultUrl} layout={'vertical'}>
                    <Form.Item label={'Urls'} name="urls" help={'Preencha com as urls separadas por vírgula'}>
                    <TextArea />
                    </Form.Item>

                    <Form.Item label={'Comparação'} name={'compare'}>
                    <Radio.Group buttonStyle="solid" onChange={(e) => {setCurrentCompare(e.target.value)}}>
                        <Radio.Button value="EQUALS">Igual a</Radio.Button>
                        <Radio.Button value="CONTAINS">Contém</Radio.Button>
                        <Radio.Button value="START_WITH">
                        Inicia em
                        </Radio.Button>
                        <Radio.Button value="DOMAIN">Domínio</Radio.Button>
                    </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            <div style={{width:'100%'}}>  
            <Row gutter={[16, 16]} justify={"space-between"}>
                <Col>
                    <Title level={4}>Urls</Title>
                </Col>
                <Col>
                    <Button type="dashed" onClick={() => setFormMultipleUrl(true)}>Cadastrar em lote</Button>
                </Col>
            </Row>
                <div style={{width:'100%'}}>
                    {currentList.map((item:OfferUrl, index:number) =>(  
                    <>
                        <Row gutter={[16,16]}>
                            <Col span={10}>
                                <Form.Item label={'Comparação'}>
                                    <Radio.Group buttonStyle="solid" defaultValue={item.compare} onChange={(e) => {OnUpdateRow('compare', e.target.value, index)}}>
                                        <Radio.Button value="EQUALS">Igual a</Radio.Button>
                                        <Radio.Button value="CONTAINS">Contém</Radio.Button>
                                        <Radio.Button value="START_WITH">
                                            Inicia em
                                        </Radio.Button>
                                        <Radio.Button value="DOMAIN">Domínio</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label={'Url'}>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                    
                                        <Input status={!!item.errors ? 'warning' : ''} placeholder="Url" size="middle" defaultValue={item.url} onChange={(e) =>{OnUpdateRow('url', e.target.value, index)}}/>
                                        {!!item.errors && (<Text type="warning">{item.errors}</Text>)}
                                    </Col>
                                    <Col span={6}>
                                        <Button
                                        type="text"
                                        icon={<MinusCircleOutlined />}
                                        onClick={() => RemoveRow(index)}
                                        >
                                        Remover
                                        </Button>
                                    </Col>
                                </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                       
                       
                    </>
                ))} 
                </div>

                <Button
                    type="dashed"
                    onClick={() => AddRow()}
                    block
                    icon={<PlusOutlined />}
                    size="middle"
                >
                Url
                </Button>
            </div>
           
        </>
    )
}

export default FormUrls;