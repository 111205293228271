import { Layout, Row, Typography } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import moment from "moment";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../classes/User";
import AppHeader from "../components/AppHeader";
import AppMenu from "../components/AppMenu";
import APP_ROUTE from "../routes/routes";
import AuthApi from "../services/AuthApi";
import { ContextProps } from "./context-props";

const { Text } = Typography;

const layoutContext = createContext({} as ContextProps);

export const useAppContext = (): ContextProps => {
  return useContext(layoutContext);
};

function useProvideContext(): ContextProps {
  const navigate = useNavigate();
  const [isLoggedUser, setIsLoggedUser] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [collapsedMenu, setCollapsedMenu] = useState(false);

  useEffect(() => {
    if (AuthApi.getToken() != "" && AuthApi.isLoggedIn()) {
      AuthApi.Me()
        .then((resp) => {
          setIsLoggedUser(AuthApi.isLoggedIn());
        })
        .catch((e) => {
          if (e.response.status == 401) {
            AuthApi.logout();
            //   setTimeout(() => {
            //     setIsLoggedUser(false);
            //     setUser(undefined);
            //     navigate(APP_ROUTE.LOGIN());
            //   }, 300);
          }
        });
    }
  }, [AuthApi.isLoggedIn()]);

  return {
    isLoggedUser,
    user,
    collapsedMenu,
    setIsLoggedUser,
    setUser,
    setCollapsedMenu
  };
}

const ProvideLayout: FC<PropsWithChildren> = ({ children }) => {
  const layout: ContextProps = useProvideContext();

  return (
    <layoutContext.Provider value={layout}>
      <Layout hasSider={layout.isLoggedUser} style={{ minHeight: "100vh" }}>
        {layout.isLoggedUser && <AppMenu />}
        <Layout>
          {layout.isLoggedUser && <AppHeader />}
          <Content className="content-wrapper">{children}</Content>
          <Footer>
            <Row justify={"center"}>
              <Text>
                Copyright &copy; {moment().format("YYYY")} CashbackBB. All
                rights reserved.
              </Text>
            </Row>
          </Footer>
        </Layout>
      </Layout>
    </layoutContext.Provider>
  );
};

export default ProvideLayout;
