import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Image
} from "antd";
import { PlusOutlined, DownOutlined, FilterOutlined } from "@ant-design/icons";
import OfferFormPage from "./form";
import { Paginated } from "../../classes/Paginated";
import { Offer } from "../../classes/Offer";
import { useNavigate } from "react-router-dom";
import APP_ROUTE from "../../routes/routes";
import OfferApi from "../../services/OfferApi";
import moment from "moment";
import UrlsForm from "./Urls";
import { CategoryDict } from "../../classes/Category";
import CategoryApi from "../../services/CategoryApi";

const { Title, Text } = Typography;

const OfferPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Paginated<Offer>>();
  const [currentItem, setCurrentItem] = useState<Offer>();
  const [showUrls, setShowUrls] = useState(false);
  const [showFormFilter, setShowFormFilter] = useState(false);
  const [categories, setCategories] = useState<CategoryDict[]>([]);
  const [showRemoved, setShowRemoved] = useState(false);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    onLoad();
    CategoryApi.list().then((resp) => {
      setCategories(resp?.data);
    });
  }, []);

  useEffect(() => {
    onLoad();
  }, [filter]);

  const OptionsMenu = (record: Offer) => {
    const [confirmLoading, setConfirmLoading] = useState(false);

    const onRestore = () => {
      setConfirmLoading(true);
      OfferApi.restoreItem(Number(record.id))
        .then((resp) => {
          message.success(resp?.data.msg);
          onLoad();
        })
        .catch((e) => {
          message.error(e.response?.data.msg);
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    };

    const onRemove = () => {
      setConfirmLoading(true);
      OfferApi.removeItem(Number(record.id))
        .then((resp) => {
          message.success(resp?.data.msg);
          onLoad();
        })
        .catch((e) => {
          message.error(e.response?.data.msg);
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    };

    const Itens = () => {
      return (
        <Menu>
          <Menu.Item onClick={() => navigate(APP_ROUTE.OFFER_FORM(record.id))}>
            Editar
          </Menu.Item>
          {record.deletedAt == null && (
            <Menu.Item
              danger
              onClick={(info) => {
                return false;
              }}
            >
              <Popconfirm
                title="Confirme"
                description={`Remover o ${record.name}?`}
                okButtonProps={{ loading: confirmLoading }}
                onConfirm={onRemove}
                onOpenChange={() => console.log("open change")}
              >
                Remover
              </Popconfirm>
            </Menu.Item>
          )}
          {!!record.deletedAt && (
            <Menu.Item
              onClick={(info) => {
                return false;
              }}
            >
              <Popconfirm
                title="Confirme"
                description={`Restaurar o ${record.name}?`}
                okButtonProps={{ loading: confirmLoading }}
                onConfirm={onRestore}
                onOpenChange={() => console.log("open change")}
              >
                Restaurar
              </Popconfirm>
            </Menu.Item>
          )}
        </Menu>
      );
    };

    return (
      <Dropdown trigger={["click"]} overlay={Itens} className={"user-dropdown"}>
        <Button type="ghost" onClick={(e) => e.preventDefault()}>
          <Space>
            Opções <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  const columns = [
    {
      title: "Imagem",
      key: "image",
      render: (text: any, record: Offer) => (
        <Image
          src={`https://stgbbchromecashback.blob.core.windows.net/images/${record.image}`}
          width={50}
        />
      )
    },
    {
      title: "Nome",
      dataIndex: "name"
    },
    {
      title: "Url de destino",
      dataIndex: "url"
    },
    {
      title: "Ativo",
      key: "enable",
      render: (text: any, record: Offer) => <>{record.enable ? "Sim" : "Não"}</>
    },
    {
      title: "Categoria",
      key: "category",
      render: (text: any, record: Offer) => <>{record.Category?.name}</>
    },
    {
      title: "Urls monitoradas",
      key: "urls",
      render: (text: any, record: Offer) => (
        <>
          <Button
            type="link"
            onClick={() => [setShowUrls(true), setCurrentItem(record)]}
          >
            {record.OfferUrls?.length}
          </Button>
        </>
      )
    },
    {
      title: "Atualizado em",
      key: "updated_at",
      render: (text: any, record: Offer) => (
        <>{moment(record.updatedAt).format("DD/MM/YYYY HH:mm")}</>
      )
    },
    {
      title: "",
      key: "options",
      render: (text: any, record: Offer) => <OptionsMenu {...record} />
    }
  ];

  const handleFilter = (values: any) => {
    setFilter({ ...filter, ...values });
  };

  function clearFilter() {
    setFilter({});
    setShowFormFilter(false);
  }
  function onLoad() {
    setLoading(true);
    OfferApi.list(filter)
      .then((resp) => {
        setData(resp?.data);
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <UrlsForm
        offer={currentItem}
        onClose={() => setShowUrls(false)}
        open={showUrls}
        onUpdate={onLoad}
      />

      <Drawer
        title={"Filtrar"}
        open={showFormFilter}
        closable={true}
        destroyOnClose={true}
        onClose={() => setShowFormFilter(false)}
      >
        <Form form={form} layout={"vertical"} onFinish={handleFilter}>
          <Form.Item name="term" label={"Nome/Url"}>
            <Input />
          </Form.Item>
          <Form.Item name="delete" valuePropName="checked">
            <Checkbox>Mostrar removidos?</Checkbox>
          </Form.Item>

          {/* <Form.Item name="enable" valuePropName="checked">
            <Checkbox>Mostrar Ativo ? </Checkbox>
          </Form.Item> */}

          <Form.Item name="category" label={"Categoria"}>
            <Select
              style={{ width: 300 }}
              placeholder="Categorias"
              options={categories.map((item) => ({
                label: item.name,
                value: item.id
              }))}
            />
          </Form.Item>

          <Form.Item>
            <Row gutter={[16, 16]}>
              <Col>
                <Button htmlType="submit" type="primary">
                  Filtrar
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={clearFilter}
                  danger
                >
                  Limpar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Drawer>

      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <Title level={3}>Ofertas</Title>
        </Col>
        <Col>
          <Row gutter={[16, 16]}>
            {categories.length > 0 && (
              <Col>
                <Button
                  type={"default"}
                  icon={<FilterOutlined />}
                  onClick={() => setShowFormFilter(true)}
                >
                  Filtrar
                </Button>
              </Col>
            )}
            <Col>
              <Button
                type={"primary"}
                icon={<PlusOutlined />}
                onClick={() => navigate(APP_ROUTE.OFFER_NEW())}
              >
                Oferta
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Table
        size="large"
        loading={loading}
        dataSource={data?.data}
        columns={columns}
        rowKey={(record) => record.id || 0}
        pagination={{
          position: ["bottomCenter"],
          pageSize: data?.size,
          current: data?.currentPage,
          total: data?.total,
          onChange: (page, pageSize) => {
            setFilter({ ...filter, ...{ page: page } });
          }
        }}
      />
    </>
  );
};

export default OfferPage;
