import { Route, Routes } from "react-router-dom";
import { useAppContext } from "../context/app-context";
import {
  PageError,
  Login,
  Onboarding,
  OfferPage,
  OfferFormPage,
  Dashboard,
  FormOnboarding
} from "../pages";
import APP_ROUTE from "./routes";




const CashBackBBRoute = () => {

 

  return (
    <Routes>
      <Route path="*" element={<PageError />} />
      <Route path={APP_ROUTE.HOME()} element={<Login />} />
      <Route path={APP_ROUTE.LOGIN()} element={<Login />} />
      <Route path={APP_ROUTE.DASHBOARD()} element={<Dashboard />}></Route>
      <Route path={APP_ROUTE.ONBOARDING()} element={<Onboarding />}></Route>
      <Route
        path={APP_ROUTE.ONBOARDING_NEW()}
        element={<FormOnboarding />}
      ></Route>
      <Route
        path={APP_ROUTE.ONBOARDING_FORM()}
        element={<FormOnboarding />}
      ></Route>
      <Route path={APP_ROUTE.OFFER()} element={<OfferPage />} />
      <Route path={APP_ROUTE.OFFER_NEW()} element={<OfferFormPage />}></Route>
      <Route path={APP_ROUTE.OFFER_FORM()} element={<OfferFormPage />}></Route>
    </Routes>
  );
};

export default CashBackBBRoute;
