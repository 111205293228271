import { useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Image,
  Col,
  Typography,
  Divider,
  message
} from "antd";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../context/app-context";
import APP_ROUTE from "../../routes/routes";
import AuthApi from "../../services/AuthApi";

const { Title, Text } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isSending, setIsSending] = useState(false);
  const { setUser, setIsLoggedUser } = useAppContext();

  const handleSubmit = (values: any) => {
    setIsSending(true);
    AuthApi.Login(values)
      .then((resp) => {
        AuthApi.setToken(resp?.data);
        setTimeout(() => {
          AuthApi.Me().then((resp) => {
            AuthApi.setUserData(resp?.data);
            setUser(resp?.data);
            setIsLoggedUser(true);
          });

          navigate(APP_ROUTE.OFFER());
        }, 300);
      })
      .catch((e) => {
        message.error(e.response.data.msg);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  return (
    <Row justify={"center"} className={"row-login"}>
      <div className="wrapper-login">
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <Image
              preview={false}
              src={require("../../assets/images/brand.png")}
              width={60}
            />
          </Col>
          <Col>
            <Title level={3} style={{ margin: 0 }}>
              Admin - Extensão BB
            </Title>
          </Col>
        </Row>
        <Divider />
        <Form form={form} onFinish={handleSubmit} layout={"vertical"}>
          <Form.Item
            name="username"
            label="Usuário"
            rules={[{ required: true, message: "Usuário é obrigatório" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Senha"
            rules={[{ required: true, message: "Senha é obrigatória" }]}
          >
            <Input type={"password"} />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              loading={isSending}
              htmlType={"submit"}
              size={"large"}
              block
            >
              Entrar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Row>
  );
};

export default Login;
