import { NamedImportBindings } from "typescript";
import BaseApi from "./BaseApi";

export default {
  listAll: (filter?: any) => {
    return BaseApi.get("/onboarding", { params: filter });
  },
  Get: (id: number) => {
    return BaseApi.get(`/onboarding/${id}`);
  },
  reorder: (body: any) => {
    return BaseApi.put("/onboarding/reorder-itens", body);
  },
  addItem: (body?: any) => {
    return BaseApi.post("/onboarding", body);
  },
  updateItem: (body: any, id: number) => {
    return BaseApi.put(`/onboarding/${id}`, body);
  },
  removeItem: (id: number) => {
    return BaseApi.delete(`/onboarding/${id}`);
  }
};
